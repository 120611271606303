<template>
  <div style="position: relative">
    <LeadsHeader />

    <!-- b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="2">
        <b-input-group prepend="Status">
          <b-form-select v-model="status" :options="leadStatusOptions" value-field="id" text-field="status"></b-form-select>
        </b-input-group>
      </b-col>
    </b-row -->

    <!-- LEADS LIST -->
    <data-table
      :uri="uri"
      :uriParams="uriParams"
      :fields="fields"
      :customItems="customItems"
      :isSelectable="true"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :filterLabel="filterLabel"
    >
      <template #headerFilters>
        <b-row>
          <b-col cols="12" lg="12">
            <b-input-group>
              <!-- b-form-input v-model="toUrlParams.franchiseCode" placeholder="Franchise Code" debounce="500"></b-form-input -->
              <!-- BaseFormSelectFranchise v-model="toUrlParams.franchiseCode" / -->
              <BaseFormFranchise
                v-if="!currentFranchiseCode"
                v-model="toUrlParams.franchiseCode"
                no-label
              />
              <b-form-select
                v-model="toUrlParams.managed"
                value-field="id"
                text-field="status"
              >
                <b-form-select-option :value="null">All</b-form-select-option>
                <b-form-select-option :value="true">Managed</b-form-select-option>
                <b-form-select-option :value="false">Non-Managed</b-form-select-option>
              </b-form-select>
              <b-form-select
                v-model="toUrlParams.status"
                :options="leadStatusOptions"
                value-field="id"
                text-field="status"
              >
                <template #first>
                  <b-form-select-option :value="null">All Statuses</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select v-model="toUrlParams.rep" :options="repList">
                <template #first>
                  <b-form-select-option :value="null">No Rep Filter</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
      </template>

      <!-- FULL NAME NAME -->
      <template #cell(fullname)="data">
        <b-btn
          size="sm"
          variant="link"
          title="View Profile"
          :to="{ name: 'LeadDetails', params: { id: data.item.id } }"
          v-b-tooltip.hover
          >{{ data.item.fname }} {{ data.item.lname }}</b-btn
        >
      </template>

      <!-- EMAIL -->
      <template #cell(email)="data">
        <b-btn
          v-if="data.value"
          size="sm"
          variant="link"
          :title="$t('label.composeAnEmail')"
          :href="'mailto:' + data.value"
          v-b-tooltip.hover
          >{{ data.value }}</b-btn
        >
      </template>

      <!-- NOTES -->
      <template #cell(notes)="data">
        <b-btn
          size="sm"
          class="text-nowrap"
          :title="$t('lead.buttonTitleNotes')"
          variant="outline-primary"
          @click="onViewNotes(data.item.id)"
          v-b-tooltip.hover
          v-b-modal.leads-list-model
        >
          <b-icon :icon="!data.value.length ? 'clipboard' : 'clipboard-check'"></b-icon
          >&nbsp;<b-icon-telephone></b-icon-telephone>
        </b-btn>
      </template>

      <!-- PHONE -->
      <template #cell(phone)="data">
        {{ data.value | formatPhoneNumber }}
      </template>

      <!-- FRANCHISE CODE -->
      <template #cell(franchiseCode)="data">
        <b-btn
          v-if="parseInt(data.value)"
          size="sm"
          variant="link"
          title="View Franchise"
          @click="ModalFranchiseShow(data.value)"
          v-b-tooltip.hover
          >{{ data.value }}</b-btn
        >
      </template>

      <!-- CREATED -->
      <template #cell(date)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>

      <!-- LAST UPDATED -->
      <template #cell(lastUpdated)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>
    </data-table>

    <!-- NOTES / PLACE A CALL -->
    <LeadModal
      :leadId="leadIdView"
      @hide="
        leadIdView = null
        refreshLeads()
      "
    /><!--  @status-update="refreshLeads()" -->

    <!-- ACTION FILTERS -->
    <b-form-select
      class="mb-4 w-auto"
      v-model="action"
      :options="[
        { value: null, text: 'With Selected...' },
        { value: 'doThis()', text: 'Do This' },
        { value: 'doThat()', text: 'Do That' },
      ]"
      @change="$log($event)"
    >
    </b-form-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTable'
import { constants } from '@/shared/constants'
import LeadsHeader from './LeadsHeader'
import LeadModal from './LeadModal'

export default {
  name: 'LeadsList',
  components: { LeadsHeader, DataTable, LeadModal },
  data() {
    return {
      uri: constants.API_URI_LEADS,
      toUrlParams: {
        status: null,
        managed: true,
        franchiseCode: this.currentFranchiseCode,
        rep: null,
      },
      filterLabel: 'Name / Zip / Email / Src',
      customItems: {
        fullName: { key: ['fname', 'lname'], label: 'Name', sortable: true }, // @TODO make sortable
      },
      defaultFields: [
        { key: 'fullname', label: 'Name', sortable: false },
        { key: 'status.status', label: 'Status', sortable: true },
        // { key: 'date', label: 'Created', sortable: true },
        { key: 'lastUpdated', label: 'Updated', sortable: true },
        { key: 'zipCode', sortable: true },
        { key: 'notes', sortable: false },
        { key: 'phone', sortable: false },
        { key: 'email', sortable: false },
      ],
      sortBy: 'date',
      sortDesc: true,
      leadIdView: null,
      action: null,
    }
  },
  computed: {
    ...mapGetters(['sscReps', 'leadStatusOptions']),
    repList() {
      return this.sscReps.map((e) => ({
        value: e['@id'],
        text: `${e.profile.fname} ${e.profile.lname}`,
      }))
    },
    uriParams() {
      const uriParams = {}
      for (const k in this.toUrlParams) {
        if (this.toUrlParams[k] !== null) uriParams[k] = this.toUrlParams[k]
      }
      return uriParams
    },
    fields() {
      const fields = this.defaultFields
      if (!this.currentFranchiseCode) {
        fields.push({ key: 'franchiseCode', label: 'Franchise', sortable: true })
      }
      return fields
    },
  },
  watch: {
    'toUrlParams.status'(val) {
      this.setStorageItem('status', val)
    },
    'toUrlParams.managed'(val) {
      this.setStorageItem('managed', val)
    },
  },
  created() {
    this.toUrlParams.franchiseCode = this.currentFranchiseCode
    this.getSscReps()
    this.getLeadStatuses()
  },
  mounted() {
    this.toUrlParams.status = this.getStorageItem('status', null)
    this.toUrlParams.managed = this.getStorageItem('managed', true)
  },
  methods: {
    ...mapActions(['getSscReps', 'getLeadStatuses']),
    refreshLeads() {
      // @XXX - hack: trigger uri change to force update
      this.uri = null
      setTimeout(() => {
        this.uri = constants.API_URI_LEADS
      }, 250)
    },
    async onViewNotes(leadId) {
      this.leadIdView = leadId
    },
  },
}
</script>
