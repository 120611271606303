<template>
  <div>
    <!-- HEADER -->
    <b-row class="d-print-none">
      <b-col class="header-filters mb-2">
        <slot name="headerFilters"></slot>
      </b-col>
      <b-col v-if="!noFilter" class="mb-2" cols="12" md="6" lg="3">
        <b-input-group size="sm" :prepend="filterLabel + ':'">
          <b-form-input type="search" v-model="filter" debounce="500" />
        </b-input-group>
      </b-col>
      <b-col cols="12" lg="2" class="text-right mb-2">
        <b-button-group size="sm">
          <BaseBtnPrint btnClass="btn btn-primary" :elem="tableId" />
          <download-excel
            class="btn btn-primary"
            :fetch="fileData"
            :fields="fileFields"
            :before-generate="fileExportStart"
            :before-finish="fileExportFinish"
            :name="fileName + '.' + fileExt"
            :title="fileTitle"
            :worksheet="worksheetTitle"
            :type="fileExt"
          >
            <b-icon icon="file-earmark-excel"></b-icon> Excel
          </download-excel>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-table
      select-mode="multi"
      stacked="lg"
      ref="DataTable"
      v-bind="bind"
      hover
      @row-selected="onRowSelected"
      @sort-changed="onSortChanged"
    >
      <template v-if="isSelectable" #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <b-icon icon="check-square-fill" variant="success"></b-icon>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <template
        v-for="slotName in Object.keys($scopedSlots)"
        v-slot:[slotName]="slotScope"
      >
        <slot :name="slotName" v-bind="slotScope"></slot>
      </template>
    </b-table>

    <!-- FOOTER -->
    <b-row class="header-filters d-print-none text-center mb-2">
      <b-col>
        <b-button-group v-if="isSelectable">
          <b-button size="sm" @click="selectAllRows">Select all</b-button>
          <b-button size="sm" @click="clearSelected" variant="warning"
            >Clear selected</b-button
          >
        </b-button-group>
      </b-col>
      <b-col cols="12" lg="3">
        {{ perPage * currentPage - perPage + 1 }} - {{ perPage * currentPage }} of
        {{ totalItems }} rows
      </b-col>
      <b-col class="d-flex justify-content-center" cols="12" lg="3">
        <b-pagination
          class="m-auto"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onPaginate"
        ></b-pagination>
      </b-col>
      <b-col>
        <b-input-group prepend="Rows">
          <b-form-select
            v-model="perPage"
            :options="[
              { value: 30, text: '30 rows' },
              { value: 60, text: '60 rows' },
              { value: 90, text: '90 rows' },
            ]"
          ></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { HTTP } from '@/services/http-common'
import ErrorService from '@/services/error-service'

export default {
  name: 'DataTable',
  props: {
    tableId: {
      type: String,
      default: () => 'datatable-table',
    },
    uri: {
      type: String,
      default: null,
    },
    uriParams: {
      type: Object,
      default: () => ({}),
    },
    filterLabel: {
      type: String,
      default: 'Filter',
    },
    filterFields: {
      type: Array,
      default: () => {
        []
      },
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: () => 'multi',
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: null,
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perPage: 30,
      currentPage: 1,
      filter: null,
      order: null,
      itemsData: [],
      totalItems: 0,
      fileExt: 'xls', // @TODO get from setting (xls|csv)
    }
  },
  computed: {
    columnFields() {
      const selected = { key: 'selected', label: '___', class: 'text-white' }
      return !this.isSelectable ? this.fields : [selected].concat(this.fields)
    },
    totalRows() {
      return !this.uri ? this.items.length : this.totalItems
    },
    currentRows() {
      return this.items.length
    },
    bind() {
      const bind = {
        selectable: this.isSelectable,
        'select-mode': this.selectMode,
        items: this.itemsData,
        fields: this.columnFields,
        noLocalSorting: this.uri !== null,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        id: this.tableId,
      }
      if (!this.uri) {
        bind.filter = this.filter
        bind['sort-by.sync'] = this.sortBy
        bind['sort-desc.sync'] = this.sortDesc
      }
      return bind
    },

    /**
     * Export properties
     */
    fileFields() {
      const fields = this.fields.reduce((a, c) => {
        let value = c.exportvalue || c.key
        a[c.label || c.key] = value
        return a
      }, {})
      if (fields.action) {
        // discard action column
        delete fields.action
      }
      return fields
    },
    fileName() {
      return 'ClubZExport-' + this.$route.name
    },
    fileTitle() {
      return `Club Z! ${this.$route.meta.label} Export `
    },
    worksheetTitle() {
      return this.fileTitle
    },
  },
  watch: {
    uri(val) {
      if (val) {
        this.request()
      }
    },
    items(data) {
      this.itemsData = data
    },
    filter(val) {
      if (this.uri) {
        this.request()
      }
      this.$emit('filtered', val)
    },
    uriParams: {
      deep: true,
      handler: 'request',
    },
    currentPage() {
      if (this.uri) {
        this.request()
      }
    },
    perPage() {
      if (this.uri) {
        this.request()
      }
    },
  },
  created() {
    this.itemsData = this.items
  },
  mounted() {
    this.setOrder(this.sortBy, this.sortDesc, true)
    if (this.uri) {
      this.request()
    }
  },
  methods: {
    setOrder(sortBy, sortDesc, useStorage) {
      const def = {
        sortBy: sortBy,
        direction: !sortDesc ? 'asc' : 'desc',
      }
      this.order = !useStorage ? def : this.getStorageItem('order', def)
      this.setStorageItem('order', this.order)
    },
    request() {
      const params = {}
      for (const k in this.uriParams) {
        if (this.uriParams[k] !== null) {
          params[k] = this.uriParams[k]
        }
      }
      params.page = this.currentPage
      params.itemsPerPage = this.perPage
      params.filter = this.filter
      if (this.order) {
        params[`order[${this.order.sortBy}]`] = this.order.direction
      }
      HTTP.get(this.uri, { params }).then(
        (resp) => {
          this.itemsData = resp.data['hydra:member']
          this.totalItems = resp.data['hydra:totalItems']
        },
        (err) => {
          ErrorService.onError(err)
        }
      )
    },
    setQueryStr() {
      URLSearchParams(this.queryParams).toString()
    },
    onPrint() {
      this.$bvModal.msgBoxOk('This feature is not yet ready')
    },
    onExport() {
      this.$bvModal.msgBoxOk('This feature is not yet ready')
    },
    onRowSelected(payload) {
      this.$emit('row-selected', payload)
    },
    onSortChanged(payload) {
      this.$emit('sort-changed', payload)
      this.setOrder(payload.sortBy, payload.sortDesc)
      this.request()
    },
    selectAllRows() {
      this.$refs.DataTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.DataTable.clearSelected()
    },
    onPaginate(data) {
      this.$emit('page-click', data)
      // if (this.uri) {}
      this.scrollTo('DataTable')
    },

    /**
     * Export properties
     */
    async fileData() {
      let ret = []
      const params = { ...this.uriParams }
      params.filter = this.filter
      if (this.order) {
        params[`order[${this.order.sortBy}]`] = this.order.direction
      }
      params.pagination = false
      await HTTP.get(this.uri, { params }).then(
        (resp) => {
          ret = resp.data['hydra:member']
        },
        (err) => {
          console.error(err)
        }
      )
      return ret
    },
    fileExportStart() {},
    fileExportFinish() {},
  },
}
</script>

<style scoped lang="scss">
.header-filters .col,
.footer-filters .col,
.header-filters div[class*='col-'],
.footer-filters div[class*='col-'] {
  margin-bottom: 0.5rem !important;
}
</style>
