<template>
  <b-modal
    id="leads-list-model"
    ref="leadListModal"
    size="xl"
    ok-title="Close"
    ok-variant="warning"
    @hidden="onModelHide"
    @ok="onModelHide"
    no-close-on-backdrop
    hide-header
    :show="leadId"
  >
    <template v-if="lead.id">
      <!-- b-row class="border-bottom mb-2 pb-2">
        <b-col>
          <h3>Lead Details</h3>
        </b-col>
        <b-col cols="12" md="3" class="text-right">
          <b-btn
            v-if="lead.phone"
            size="sm"
            title="Place a Call"
            variant="outline-primary"
            @click="onPlaceCall"
            v-b-tooltip.hover
          >
            <b-icon-telephone></b-icon-telephone> {{ lead.phone | formatPhoneNumber }}
          </b-btn> <b-btn
            size="sm"
            title="Start an Enrollment"
            variant="success"
            @click="onEnroll"
            v-b-tooltip.hover
          >
            Start Enrollment <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
          </b-btn>
        </b-col>
      </b-row -->

      <LeadDetails
        :leadId="leadId"
        @updated="onLeadUpdated()"
        :disableFieldEdit="['status']"
      />
      <b-container>
        <LeadNoteForm class="mb-4" :leadId="lead['@id']" ref="leadNoteForm" no-buttons />
        <LeadNotes :leadId="leadId" />
      </b-container>
    </template>
    <template v-else>
      <p class="text-center">LOADING...</p>
    </template>

    <template #modal-footer>
      <b-row no-gutters>
        <!-- b-col cols="3" lg="2" class="text-right pr-2">
          <b-button
            size="md"
            title="Close without updating"
            variant="warning"
            class="text-white"
            @click="ok()"
            v-b-tooltip.hover
          >
            Close
          </b-button>
        </b-col -->
        <b-col cols="12" lg="4">
          <b-input-group size="md" prepend="Status:">
            <b-form-select
              class="w-auto"
              v-model="newStatus"
              :options="leadStatusOptions"
              value-field="id"
              text-field="status"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Status</b-form-select-option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-button
                title="Update this lead"
                variant="success"
                @click="onSubmit"
                v-b-tooltip.hover
              >
                Save
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeadDetails from '@/components/lead/LeadDetails'
import LeadNotes from '@/components/lead/LeadNotes'
import LeadNoteForm from '@/components/lead/LeadNoteForm'

export default {
  name: 'LeadModal',
  props: {
    leadId: {
      type: Number,
      default: null,
    },
    noButtons: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { LeadDetails, LeadNotes, LeadNoteForm },
  data() {
    return {
      newNote: null,
      newStatus: null,
    }
  },
  computed: {
    ...mapGetters(['lead', 'leadStatusOptions']),
  },
  methods: {
    ...mapActions(['getLead', 'putLead', 'resetLead', 'postLeadNote', 'getLeadNotes']),
    validate() {
      if (!this.newStatus) {
        this.toastError('You must select a status!')
      }
    },
    async onSubmit() {
      try {
        this.validate()
        this.$refs.leadNoteForm.onSubmit()

        const newStatusText = this.leadStatusOptions.find(
          ({ id }) => id === this.newStatus
        ).status
        await this.putLead({
          id: this.lead.id,
          status: this.newStatus,
        })
        this.$bvModal.hide('leads-list-model')
        this.toastSuccess('Status updated to ' + newStatusText)
        this.$emit('status-update')
        this.onModelHide()
        this.newStatus = null
      } catch (e) {
        console.error(e)
      }
    },
    onLeadUpdated() {
      this.getLeadNotes({
        lead: this.leadId,
        'order[id]': 'desc',
      })
    },
    onModelHide() {
      this.resetLead()
      this.$emit('hide')
    },
    onStatusUpdate() {
      const newStatusText = this.leadStatusOptions.find(({ id }) => id === this.newStatus)
        .status

      this.$bvModal.hide('leads-list-model')
      this.$bvModal.msgBoxOk('Status updated to ' + newStatusText)
      this.$emit('status-update')
      this.onModelHide()
    },
  },
  watch: {
    async leadId(val) {
      if (!val) return
      await this.getLead(this.leadId)
      // this.newStatus = this.lead.status.id
    },
  },
}
</script>

<style>
#leads-list-model .modal-footer {
  display: inline;
}
</style>
